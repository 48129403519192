<template>
  <full-page-gradient>
    <v-layout justify-center class="no-grow">
      <v-flex xs12>
        <typography type="h3">Success!</typography>
      </v-flex>
    </v-layout>
    <v-layout class="no-grow" justify-center>
      <v-flex xs12>
        <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light
          >We are now processing your application.</typography
        >
      </v-flex>
    </v-layout>
    <v-layout class="no-grow home-button-container" justify-center>
      <v-flex xs12>
        <round-button
          name="CardPreferencesSuccessHomeButton"
          outline
          color="white"
          @click="onHomeClick"
          >Back to home</round-button
        >
      </v-flex>
    </v-layout>
  </full-page-gradient>
</template>

<script>
import FullPageGradient from '@/components/templates/FullPageGradient.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { HOME } from '@/constants/routes';

export default {
  components: {
    Typography,
    FullPageGradient,
    RoundButton
  },
  methods: {
    onHomeClick() {
      window.location.href = HOME;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.no-grow {
  flex-grow: 0;
}
.home-button-container {
  padding-top: 35px;
}
</style>
